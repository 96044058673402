import React from 'react'
import styled from 'styled-components'

import { Layout, SEO } from '../components'
import theme from '../../config/theme'

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    flex-wrap: wrap;
  }
  @media (min-width: ${theme.breakpoints.desktop}) {
    flex-wrap: wrap;
  }
`

const Ad = styled.div`
  margin: 10px;
  background-color: #ccc;
  width: 300px;
/*  height: 250px; */
`

const Content = styled.div`
  text-align: justify;
  margin: 10px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    width: 700px;
  }
  @media (min-width: ${theme.breakpoints.desktop}) {
    width: 700px;
  }
`

export default () => (
  <Layout>
    <SEO />
    <Row>
      <Content>
        <div id="search" />
      </Content>
      <Ad>
        <div id="destination-1" />
      </Ad>
      <Ad>
        <div id="destination-2" />
      </Ad>
    </Row>
  </Layout>
)